<template>
  <div id="posting-activity-report">
    <CRow>
      <CCol>
        <Widgets :data="data" :features="features" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RTableFilterOptions :config="filterConfig" @change="handleFilterChange" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <PostingActivityTable
          :data="data"
          :page="page"
          :page-size="pageSize"
          :loading="loading"
          :features="features"
          @fetch="fetchActivityReport"
          @page-change="onPageChange"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { formatISO } from 'date-fns'

import { RTableFilterOptions } from '@/components/tables'
import { paginatedRequest } from '@/services/api'
import { ReportingAPI } from '@/services/api/resources'
import { formatDate } from '@/utils'

import PostingActivityTable from './PostingActivityTable'
import Widgets from './Widgets'

export default {
  name: 'PostingActivityReport',
  components: {
    PostingActivityTable,
    RTableFilterOptions,
    Widgets
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    features: {
      type: Object,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      data: {
        results: [],
        count: 0,
        page: this.page,
        page_size: this.pageSize,
        metrics: {
          total_renews: 0,
          total_emails: 0,
          total_visits: 0,
          total_redirects: 0
        }
      },
      page: 1,
      pageSize: 50,
      filters: {
        bedroom: [],
        bathroom: [],
        manual_post: [],
        search: '',
        price: ''
      },
      filterConfig: {
        bedroom: {
          type: 'multiselect',
          label: 'Bedroom',
          options: [
            { value: 0, label: '0' },
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' },
            { value: 6, label: '6' },
            { value: 7, label: '7' },
            { value: 8, label: '8' }
          ]
        },
        bathroom: {
          type: 'multiselect',
          label: 'Bathroom',
          options: [
            { value: 0, label: '0' },
            { value: 1, label: '1' },
            { value: 1.5, label: '1.5' },
            { value: 2, label: '2' },
            { value: 2.5, label: '2.5' },
            { value: 3, label: '3' },
            { value: 3.5, label: '3.5' },
            { value: 4, label: '4' },
            { value: 4.5, label: '4.5' },
            { value: 5, label: '5' },
            { value: 5.5, label: '5.5' },
            { value: 6, label: '6' },
            { value: 6.5, label: '6.5' },
            { value: 7, label: '7' },
            { value: 7.5, label: '7.5' },
            { value: 8, label: '8' },
            { value: 8.5, label: '8.5' },
            { value: 9, label: '9' }
          ]
        },
        manual_post: {
          type: 'multiselect',
          label: 'Posted Manually',
          options: [
            {
              label: 'Yes',
              value: true
            },
            {
              label: 'No',
              value: false
            }
          ]
        },
        price: {
          type: 'input',
          label: 'Price'
        },
        search: {
          type: 'input',
          label: 'Search'
        }
      }
    }
  },
  watch: {
    dateRange () {
      this.page = 1
      this.fetchActivityReport()
    }
  },
  async created () {
    await this.fetchActivityReport()

    if (this.data.results.length === 0) {
      this.$emit('no-data')
    }
  },
  methods: {
    /**
     * Format an APIv2 date fields.
     *
     * @param {(String|null)} date
     * @param {(String|null)} fmt
     * @returns {String}
     */
    formatDate (date, fmt) {
      if (!date) {
        return 'Unknown'
      }
      return formatDate(date, fmt)
    },
    /**
     * Get params for the report
     */
    getParams () {
      const params = new URLSearchParams()
      params.append('from_date', formatISO(this.dateRange.start))
      params.append('to_date', formatISO(this.dateRange.end))
      params.append('tz', this.dateRange.tz)
      params.append('page', this.page)

      this.filters.bedroom.forEach(value => params.append('bedroom_count', value))
      this.filters.bathroom.forEach(value => params.append('bathroom_count', value))
      if (this.filters.manual_post && this.filters.manual_post.length === 1) {
        params.append('manual_post', this.filters.manual_post)
      }
      if (this.filters.price) {
        params.append('price', this.filters.price)
      }
      if (this.filters.search) {
        params.append('q', this.filters.search)
      }
      return params
    },
    /**
     * Fetch the posting activity report.
     */
    async fetchActivityReport () {
      this.$emit('loading', true)
      const params = this.getParams()
      params.append('page_size', this.pageSize)

      const response = await ReportingAPI.property.posting_activity({
        id: this.$route.params.pid,
        params
      })
      if (response) {
        this.data = response.data
      }
      this.$emit('loading', false)
    },
    /**
     * onRefresh handler called by BaseReport component.
     */
    refresh () {
      this.fetchActivityReport()
    },
    /**
     * onExport handler called by BaseReport component.
     */
    async exportData () {
      const formattedData = []
      const params = this.getParams()
      this.$emit('loading', true)
      const response = await paginatedRequest(ReportingAPI.property.posting_activity, this.$route.params.pid, params)
      this.$emit('loading', false)
      for (const row in response.data) {
        const ad = response.data[row]
        /* eslint-disable quote-props */
        formattedData.push({
          'Published': this.formatDate(ad.published, 'yyyy-MM-dd / HH:mm OOOO'),
          'Ad Title': ad.title || '',
          'Price': ad.price || '',
          'Bedroom Count': ad.bedroom_count || '',
          'Bathroom Count': ad.bathroom_count || '',
          'Craigslist URL': ad.view_url || '',
          'Automatic Renewals': ad.renew_count || '',
          'Email Leads': ad.email_leads || '',
          'Website Visits': ad.visit_count || ''
        })
      }
      return formattedData
    },
    /**
     * Update search filters for ads.
     */
    handleFilterChange (filters) {
      this.filters = filters
      this.fetchActivityReport()
    },
    onPageChange (pageData) {
      this.page = pageData.page
      this.pageSize = pageData.pageSize
      this.fetchActivityReport()
    }
  }
}
</script>
